<template>
    <b-overlay :show="showLoader">
        <b-card >
            <b-alert class="p-1 text-center" show variant="warning">Po spremembi je potrebnih nekaj sekund, da so le te vidne. Če spremembe niso vidne, poskusite osvežiti spletno stran.</b-alert>
            <BusinessPrivacyPolicyComponent :model="object" />
            <div class="d-flex justify-content-end mt-2">
                <b-button @click="edit">Uredi</b-button>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
    import BusinessPrivacyPolicyComponent from './BusinessPrivacyPolicyComponent.vue'
    import {BAlert, BButton, BCard, BOverlay} from 'bootstrap-vue'
    export default {
        components: {
            BusinessPrivacyPolicyComponent,
            BCard,
            BButton,
            BAlert,
            BOverlay
        },            
        data() {
            return {
                showLoader: false,
                object:
                    {
                        title: '',
                        content: '',
                        active: true,
                        manager: ''
                    }
            }
        },
        methods:{
            async edit() {
                try {
                    this.showLoader = true
                    await this.$http.patch(`/api/management/v1/business_privacy_policies/${this.$route.params.id}`, this.object)
                    this.$printSuccess('Pogoji so bili posodobljeni')
                    setTimeout(() => {
                        this.$router.push({name: 'business-privacy-policies'})
                    }, 1000)
                } catch (err) {
                    this.$printError('Prislo je do napake pri urejanju')
                } finally {
                    this.showLoader = false
                }
            },
            async loadData() {
                try {
                    this.showLoader = true
                    const response = await this.$http.get(`/api/management/v1/business_privacy_policies/${this.$route.params.id}`)
                    this.object = response.data
                } catch (err) {
                    this.$printError('Prišlo je do napake pri nalaganju podatkov')
                } finally {
                    this.showLoader = false
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>
    .item-description-list{
        border: 2px solid grey;
        padding: 35px 10px 10px 10px;
        position: relative;
    }

    .delete-from-list{
        color: red;
        font-weight: 900;
        font-size: 17px;
        position: absolute;
        top: 5px;
        right: 10px;
        cursor: pointer;
    }
</style>